/* src/Menu.css */
.menu {
  background-color: #333;
  padding: 10px;
  position: fixed; /* ou relative si vous ne voulez pas qu'il soit fixé */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}


.menu ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.menu li {
  margin: 0 15px;
}

.menu a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.menu a:hover {
  text-decoration: underline;
}
